import { Suspense } from 'react';

import AddToCart from 'Component/AddToCart';
import CartIcon from 'Component/CartIcon';
import If from 'Component/If';
import Image from 'Component/Image';
import { ImageRatio } from 'Component/Image/Image.type';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import {
    ProductCardComponent as SourceProductCardComponent,
} from 'SourceComponent/ProductCard/ProductCard.component';
import { ReactElement } from 'Type/Common.type';
import { isSignedIn } from 'Util/Auth/IsSignedIn';

import './ProductCard.override.style';

/** @namespace Pwa/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {
    renderPicture(mix = {}): ReactElement {
        const { product: { id, name }, thumbnail, onLoad } = this.props;

        return (
            <div>
                <Image
                  imageRef={ this.imageRef }
                  src={ thumbnail }
                  alt={ name }
                  ratio={ ImageRatio.IMG_CUSTOM }
                  mix={ { block: 'ProductCard', elem: 'Picture', mix } }
                  isPlaceholder={ !id }
                  onImageLoad={ onLoad }
                />
                <img
                  style={ { display: 'none' } }
                  alt={ name }
                  src={ thumbnail }
                />
            </div>
        );
    }

    renderAddToCart(): ReactElement {
        const {
            showSelectOptionsNotification,
            inStock,
        } = this.props;

        const requiresConfiguration = this.requiresConfiguration();

        if (inStock && requiresConfiguration) {
            return (
                <button
                  block="ProductAddToCart"
                  onClick={ showSelectOptionsNotification }
                >
                    <CartIcon />
                </button>
            );
        }

        if (!inStock) {
            return (
                <div block="ProductCard" elem="OutOfStock">
                    <p>
                        { __('Out of stock') }
                    </p>
                </div>
            );
        }

        return this.renderAddToCartButton();
    }

    // #region BUTTONS
    renderAddToCartButton(layout = CategoryPageLayout.GRID): ReactElement {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            updateSelectedValues,
        } = this.props;

        const { sku } = getActiveProduct();

        if (!sku) {
            return <TextPlaceholder length={ TextPlaceHolderLength.BLOCK } />;
        }

        return (
            <Suspense fallback={ null }>
                <AddToCart
                  mix={ { block: this.className, elem: 'AddToCart' } }
                  addToCart={ addToCart }
                  isDisabled={ !inStock }
                  isIconEnabled
                  layout={ layout }
                  updateSelectedValues={ updateSelectedValues }
                  quantity={ quantity }
                  product={ getActiveProduct() }
                />
            </Suspense>
        );
    }

    renderBrand(withMeta = false): ReactElement {
        const {
            product: {
                attributes: { sub_family: { attribute_options = {}, attribute_value = '' } = {} } = {},
            },
        } = this.props;

        const subFamilyLabel = attribute_options?.[attribute_value]?.label;

        if (!subFamilyLabel) {
            return null;
        }

        return (
            <>
                { withMeta && <meta itemProp="brand" content={ subFamilyLabel } /> }
                <h4 block={ this.className } elem="Brand" itemProp="brand">
                    <TextPlaceholder content={ subFamilyLabel } />
                </h4>
            </>
        );
    }

    renderProductActions(): ReactElement {
        return (
            <div block="ProductCard" elem="ProductActions">
                { this.renderProductCardWishlistButton() }
                { /* { this.renderProductCompareButton() } */ }
            </div>
        );
    }

    renderProductCardWishlistButton(): ReactElement {
        const { hideWishlistButton, isWishlistEnabled, getActiveProduct } = this.props;

        if (hideWishlistButton || !isWishlistEnabled) {
            return null;
        }

        const { sku } = getActiveProduct();

        if (!sku) {
            return <TextPlaceholder length={ TextPlaceHolderLength.BLOCK } />;
        }

        return this.renderWishlistButton();
    }

    renderSku(): ReactElement {
        const { getActiveProduct } = this.props;
        const { sku, type_id } = getActiveProduct();

        return (
            <If condition={ !!sku && type_id !== 'configurable' }>
                <span block="ProductCard" elem="Sku" itemProp="sku">
                    <b>{ __('Cart.code') }</b>
                    { ' ' }
                    <TextPlaceholder content={ sku } />
                </span>
                { ' ' }
            </If>
        );
    }

    renderCardContent(): ReactElement {
        const { renderContent, product: { name } } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            <div block="ProductCard" elem="LinkInnerWrapper" mods={ { loaded: !!name } }>
                { this.renderCardLinkWrapper((
                    <>
                        <div block="ProductCard" elem="FigureReview">
                            <figure block="ProductCard" elem="Figure">
                                { /* { isMobile && (
                                <div block="ProductCard" elem="MobileActions">
                                    { this.renderProductCardWishlistButton() }
                                    { this.renderProductCompareButton() }
                                </div>
                            ) } */ }
                                { this.renderPicture() }
                            </figure>
                        </div>
                        <div block="ProductCard" elem="Content">
                            { /* { this.renderReviews() } */ }
                            { this.renderBrand() }
                            { this.renderName(false) }
                            { this.renderSku() }
                            { /* { this.renderConfigurableOptions() } */ }
                        </div>
                    </>
                )) }
                <div block="ProductCard" elem="Bottom">
                    { this.renderPrice() }
                    <If condition={ isSignedIn() }>
                        <div block="ProductCard" elem="Footer">
                            { this.renderProductActions() }
                            { this.renderCardLinkWrapper(this.renderAddToCart()) }
                        </div>
                    </If>
                </div>
            </div>
        );
    }
}

export default ProductCardComponent;
